import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/apps/dashboard'
  { path: '', pathMatch: 'full', redirectTo: 'apps/dashboard' },

  // Redirect signed in user to the '/apps/dashboard'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'apps/dashboard',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Apps
      {
        path: 'apps',
        children: [
          {
            path: 'dashboard',
            loadChildren: () =>
              import('app/modules/admin/apps/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
              ),
          },
          {
            path: 'attendee',
            loadChildren: () =>
              import('app/modules/admin/apps/attendee/attendee.module').then(
                (m) => m.AttendeeModule
              ),
          },
          {
            path: 'event-fair',
            loadChildren: () =>
              import(
                'app/modules/admin/apps/event-fair/event-fair.module'
              ).then((m) => m.EventFairModule),
          },
          {
            path: 'role',
            loadChildren: () =>
              import('app/modules/admin/apps/role/role.module').then(
                (m) => m.RoleModule
              ),
          },
          {
            path: 'users',
            loadChildren: () =>
              import(
                'app/modules/admin/apps/user-player/user-player.module'
              ).then((m) => m.UserPlayerModule),
          },
          {
            path: 'country-zone',
            loadChildren: () =>
              import(
                'app/modules/admin/apps/country-zone/country-zone.module'
              ).then((m) => m.CountryZoneModule),
          },
          {
            path: 'meeting-calc',
            loadChildren: () =>
              import(
                'app/modules/admin/apps/meeting-calc/meeting-calc.module'
              ).then((m) => m.MeetingCalcModule),
          },
        ],
      },
      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import(
            'app/modules/admin/pages/error/error-404/error-404.module'
          ).then((m) => m.Error404Module),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
