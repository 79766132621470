export const environment = {
  name: 'dev',
  production: false,
  baseApi: 'https://mmm-api-dev.econcloud.net/v1',
  baseClientUrl: 'https://mmm-backoffice-dev.econcloud.net',
  cdnUrl: 'https://mmm-assets.econcloud.net/dev',
  webEventUrl: 'https://mmm-event-dev.econcloud.net',
  pathApi: {
    auth: {
      signin: 'auth/login',
      signinByToken: 'auth/profile',
      refreshToken: 'auth/refresh',
      forgotpassword: 'auth/forgot-password',
      resetpassword: 'auth/reset-password',
    },
    upload: {
      file: 'upload',
    },
    company: {
      get: 'company',
      getPagination: 'company',
      create: 'company',
      update: 'company',
    },
    brand: {
      get: 'brand',
      getPagination: 'brand',
      create: 'brand',
      update: 'brand',
    },
    product: {
      get: 'product',
      getPagination: 'product',
      create: 'product',
      update: 'product',
    },
    vending: {
      get: 'vending',
      getPagination: 'vending',
      create: 'vending',
      update: 'vending',
    },
    role: {
      get: 'role',
      getPagination: 'role',
      create: 'role',
      update: 'role',
    },
    policy: {
      get: 'policy',
      getPagination: 'policy',
      create: 'policy',
      update: 'policy',
    },
    newsletter: {
      getPagination: 'vending',
      create: 'vending',
      update: 'vending',
    },
    userPlayer: {
      get: 'user',
      getPagination: 'user',
      create: 'auth/register',
      update: 'user',
    },
    quest: {
      get: 'quest',
      getPagination: 'quest',
      create: 'quest',
      update: 'quest',
    },
    chatRoom: {
      get: 'chat',
      getPagination: 'chat',
      create: 'chat',
      update: 'chat',
    },
    eventCompany: {
      get: 'event-company',
      getPagination: 'event-company',
      create: 'event-company',
      update: 'event-company',
    },
    eventFair: {
      get: 'event-fair',
      getPagination: 'event-fair',
      create: 'event-fair',
      update: 'event-fair',
    },
    eventSchedule: {
      get: 'event-schedule',
      getPagination: 'event-schedule',
      create: 'event-schedule',
      update: 'event-schedule',
    },
    countryZone: {
      get: 'country-zone',
      getPagination: 'country-zone',
      create: 'country-zone',
      update: 'country-zone',
    },
    attendee: {
      get: 'attendee',
      getPagination: 'attendee',
      create: 'attendee',
      update: 'attendee',
    },
    questionForm: {
      get: 'question-form',
    },
  },
  signInPage: {
    showSignup: false,
    showForgotPassword: true,
    showSocialSignin: false,
  },
};
