import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { CountryZone } from 'app/modules/admin/apps/country-zone/country-zone.types';
import { UserSelectorService } from 'app/services/userSelector.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styles: [],
})
export class UserSelectorComponent implements OnInit {
  filterCountryInputControl: UntypedFormControl = new UntypedFormControl();

  allCountryZone: CountryZone[] = [];
  userInfo: User;

  constructor(
    private userService: UserService,
    private userSelectorService: UserSelectorService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.userService.currentUser;
    this.allCountryZone = this.userInfo.countryManage;

    this.allCountryZone.sort(this.sortByTitle);



    this.filterCountryInputControl.patchValue(
      this.userSelectorService._countryZoneCode.value
    );
  }

  onSelectCountry(countryZoneCode: string): void {
    this.userSelectorService.updateCountryZoneCode(countryZoneCode);
  }

  trackByFn(index: number, item: CountryZone): any {
    return item.countryZoneCode;
  }

  sortByTitle(a: CountryZone, b: CountryZone): number {
    const titleA = a.name.toLowerCase();
    const titleB = b.name.toLowerCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  }
}
