import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  Observable,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress: boolean = false;
  private refreshToken$ = new BehaviorSubject<string | null>(null);

  /**
   * Constructor
   */
  constructor(private _authService: AuthService) {}

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(this.addTokenHeader(req, this._authService.accessToken))
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.processRequestError(error, req, next)
        )
      );
  }

  private processRequestError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.pathApi.auth.refreshToken)) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this._authService.signOut();
        location.reload();
      }
    }

    if (error instanceof HttpErrorResponse && error.status === 401) {
      return this.refreshToken(request, next);
    }

    if (error instanceof HttpErrorResponse && error.status === 403) {
      this._authService.signOut();
      location.reload();
    }

    return throwError(() => error);
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshToken$.next(null);

      const token = this._authService.refreshToken;
      if (token) {
        return this._authService.signInUsingRefreshToken().pipe(
          switchMap((isSuccess: boolean) => {
            if (!isSuccess) {
              this._authService.signOut();
              location.reload();
            }

            this.refreshTokenInProgress = false;
            this.refreshToken$.next(this._authService.accessToken);
            return next.handle(
              this.addTokenHeader(request, this._authService.accessToken)
            );
          })
        );
      }
    }

    return this.refreshToken$.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((_token) => next.handle(this.addTokenHeader(request, _token)))
    );
  }

  private addTokenHeader(
    request: HttpRequest<any>,
    token: string | null
  ): HttpRequest<any> {
    const excludePath = [
      `${window.location.origin}/assets`,
      'https://mmm-assets.econcloud.net',
    ];

    if (
      token &&
      !request.url.includes(environment.pathApi.auth.refreshToken) &&
      excludePath.findIndex((f) => request.url.includes(f)) === -1
    ) {
      request = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token),
      });
    }

    return request;
  }
}
