import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputAutoComplateOffModule } from 'app/utils/directives/auto-complate-off/input-auto-complate-off.module';
import { SearchByKeyPipe } from 'app/utils/pipes/search-by-key.pipe';

@NgModule({
  declarations: [SearchByKeyPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputAutoComplateOffModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputAutoComplateOffModule,
    SearchByKeyPipe,
  ],
})
export class SharedModule {}
