import { Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSelectorService {
  public _countryZoneCode: BehaviorSubject<string> = new BehaviorSubject('');

  private _saveKeyCountryZone = 'latest_cz_select';
  private _saveSelectedCountryZone: Record<string, string> = {};

  public _dashboardEvent: BehaviorSubject<string> = new BehaviorSubject('');
  private _saveKeyDashboard = 'latest_dashboard_select';
  private _saveSelectedDashboard: Record<string, string> = {};

  constructor(private userService: UserService) {
    const loadSaveCountryZone = localStorage.getItem(this._saveKeyCountryZone);

    if (loadSaveCountryZone) {
      this._saveSelectedCountryZone = JSON.parse(loadSaveCountryZone);
    }

    const loadSaveDashboard = localStorage.getItem(this._saveKeyDashboard);

    if (loadSaveDashboard) {
      this._saveSelectedDashboard = JSON.parse(loadSaveDashboard);
    }
  }

  get countryZoneCode(): Observable<string> {
    return this._countryZoneCode.asObservable();
  }

  get dashboardEvent(): Observable<string> {
    return this._dashboardEvent.asObservable();
  }

  public onLoadCountryZone(): void {
    const latestCZ =
      this._saveSelectedCountryZone[this.userService.currentUser.id];
    if (latestCZ) {
      this._countryZoneCode.next(latestCZ);
    }
  }

  public updateCountryZoneCode(
    countryZoneCode: string,
    emit: boolean = true
  ): void {
    if (emit || !this._countryZoneCode.value) {
      this._countryZoneCode.next(countryZoneCode);

      this._saveSelectedCountryZone = {
        [this.userService.currentUser.id]: countryZoneCode,
      };

      localStorage.setItem(
        this._saveKeyCountryZone,
        JSON.stringify(this._saveSelectedCountryZone)
      );
    }
  }

  public onLoadDashboard(): void {
    const latestDashboard =
      this._saveSelectedDashboard[this.userService.currentUser.id];
    if (latestDashboard) {
      this._dashboardEvent.next(latestDashboard);
    }
  }

  public updateDashboard(eventFairCode: string, emit: boolean = true): void {
    if (emit || !this._dashboardEvent.value) {
      this._dashboardEvent.next(eventFairCode);

      this._saveSelectedDashboard = {
        [this.userService.currentUser.id]: eventFairCode,
      };

      localStorage.setItem(
        this._saveKeyDashboard,
        JSON.stringify(this._saveSelectedDashboard)
      );
    } else {
      this._saveSelectedDashboard = {
        [this.userService.currentUser.id]: eventFairCode,
      };

      localStorage.setItem(
        this._saveKeyDashboard,
        JSON.stringify(this._saveSelectedDashboard)
      );
    }
  }
}
