import { Injectable } from '@angular/core';
import { CountryZone } from './country-zone.types';
import { HttpClient } from '@angular/common/http';
import {
  Pagination,
  BaseResponse,
  DataWithPagination,
} from 'app/utils/core.types';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  Observable,
  map,
  tap,
  take,
  switchMap,
  of,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryZoneService<T extends CountryZone> {
  private readonly getListApi = `${environment.baseApi}/${environment.pathApi.countryZone.get}/list`;
  private readonly getByIdApi = `${environment.baseApi}/${environment.pathApi.countryZone.get}`;
  private readonly getPaginationApi = `${environment.baseApi}/${environment.pathApi.countryZone.getPagination}`;
  private readonly createApi = `${environment.baseApi}/${environment.pathApi.countryZone.create}`;
  private readonly updateApi = `${environment.baseApi}/${environment.pathApi.countryZone.update}`;
  private readonly getListOpenApi = `${environment.baseApi}/open-api/country-zone/list`;

  private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(
    null
  );
  private _datas: BehaviorSubject<T[] | null> = new BehaviorSubject(null);
  private _datasAll: BehaviorSubject<T[] | null> = new BehaviorSubject([]);

  constructor(private _httpClient: HttpClient) {}

  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  get datas$(): Observable<T[]> {
    return this._datas.asObservable();
  }

  get _datasAll$(): Observable<T[]> {
    return this._datasAll.asObservable();
  }

  all(): Observable<T[]> {
    if (this._datasAll.value.length > 0) {
      return this._datasAll$;
    }

    return this._httpClient.get<BaseResponse<T[]>>(this.getListOpenApi).pipe(
      tap((response) => {
        this._datasAll.next(response.result);
      }),
      map((res) => res.result)
    );
  }

  getById(id: string): Observable<T> {
    return this._httpClient
      .get<BaseResponse<T>>(`${this.getByIdApi}/${id}`)
      .pipe(map((res) => res.result));
  }

  getDataWithPagination(
    page: number = 0,
    size: number = 20,
    sort: string = 'createdAt',
    order: 'asc' | 'desc' | '' = 'desc',
    search: string = ''
  ): Observable<DataWithPagination<T>> {
    return this._httpClient
      .get<BaseResponse<DataWithPagination<T>>>(this.getPaginationApi, {
        params: {
          page: page + 1,
          limit: size,
          sort,
          direction: order || 'desc',
          search,
        },
      })
      .pipe(
        tap((response) => {
          this._pagination.next(response.result.pagination);
          this._datas.next(response.result.data);
        }),
        map((res) => res.result)
      );
  }

  createMockupData(): Observable<T> {
    return this.datas$.pipe(
      take(1),
      switchMap((resp) => {
        const newData = { id: 'mock' } as T;
        this._datas.next([newData, ...resp]);

        return of(newData);
      })
    );
  }

  cleanMockup(): Observable<T[]> {
    return this.datas$.pipe(
      take(1),
      map((resp) => {
        const newData = resp.filter((f) => f.id !== 'mock');
        this._datas.next(newData);
        return newData;
      })
    );
  }

  create(data: T): Observable<any> {
    return this._httpClient
      .post<BaseResponse<any>>(`${this.createApi}`, {
        ...data,
      })
      .pipe(take(1));
  }

  update(id: string, data: T): Observable<T> {
    return this._httpClient
      .patch<BaseResponse<T>>(`${this.updateApi}/${id}`, {
        ...data,
      })
      .pipe(
        take(1),
        map((m) => m.result)
      );
  }
}
