import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  map,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { UserSelectorService } from 'app/services/userSelector.service';
import { CountryZoneService } from 'app/modules/admin/apps/country-zone/country-zone.service';
import { CountryZone } from 'app/modules/admin/apps/country-zone/country-zone.types';
import { User, UserLogin } from '../user/user.types';
import { BaseResponse } from 'app/utils/core.types';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;

  private loginAPI = `${environment.baseApi}/${environment.pathApi.auth.signin}`;
  private refreshTokenAPI = `${environment.baseApi}/${environment.pathApi.auth.refreshToken}`;
  private getProfileAPI = `${environment.baseApi}/${environment.pathApi.auth.signinByToken}`;
  private forgotpasswordAPI = `${environment.baseApi}/${environment.pathApi.auth.forgotpassword}`;
  private resetPasswordAPI = `${environment.baseApi}/${environment.pathApi.auth.resetpassword}`;

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _userSelectorService: UserSelectorService,
    private countryZoneService: CountryZoneService<CountryZone>
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  get accessToken(): string {
    return localStorage.getItem(this.JWT_TOKEN) ?? '';
  }

  set refreshToken(token: string) {
    localStorage.setItem(this.REFRESH_TOKEN, token);
  }

  get refreshToken(): string {
    return localStorage.getItem(this.REFRESH_TOKEN) ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post(this.forgotpasswordAPI, {
      email,
    });
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: {
    password: string;
    newPasswordToken: string;
  }): Observable<any> {
    return this._httpClient.post(this.resetPasswordAPI, {
      newPasswordToken: data.newPasswordToken,
      newPassword: data.password,
    });
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post<
        BaseResponse<{
          user: UserLogin;
          accessToken: string;
          refreshToken: string;
        }>
      >(this.loginAPI, credentials)
      .pipe(
        map((res) => res.result),
        switchMap((userResp) => {
          if (userResp.user.roleCode === 'HOST') {
            return of({
              accessToken: userResp.accessToken,
              refreshToken: userResp.refreshToken,
              user: userResp.user,
              countryZone: userResp.user.countryManage.map(
                (m) => m.countryZone
              ),
            });
          }

          return this.countryZoneService.all().pipe(
            take(1),
            map((cz) => {
              return {
                accessToken: userResp.accessToken,
                refreshToken: userResp.refreshToken,
                user: userResp.user,
                countryZone: cz,
              };
            })
          );
        }),
        switchMap((response) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;
          this.refreshToken = response.refreshToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = {
            ...response.user,
            avatar: response.user?.image_url,
            countryManage: response.countryZone,
          };

          this._userSelectorService.onLoadCountryZone();
          this._userSelectorService.updateCountryZoneCode(
            response.countryZone[0].countryZoneCode,
            false
          );

          // Return a new observable with the response
          return of(response);
        })
      );
  }

  /**
   * Sign in using the refresh access token
   */
  signInUsingRefreshToken(): Observable<boolean> {
    // Sign in using the token
    return this._httpClient
      .get<
        BaseResponse<{
          user: UserLogin;
          accessToken: string;
          refreshToken: string;
        }>
      >(this.refreshTokenAPI, {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${this.refreshToken}`,
        },
      })
      .pipe(
        map((res) => res.result),
        switchMap((userResp) => {
          if (userResp.user.roleCode === 'HOST') {
            return of({
              accessToken: userResp.accessToken,
              refreshToken: userResp.refreshToken,
              user: userResp.user,
              countryZone: userResp.user.countryManage.map(
                (m) => m.countryZone
              ),
            });
          }

          return this.countryZoneService.all().pipe(
            take(1),
            map((cz) => {
              return {
                accessToken: userResp.accessToken,
                refreshToken: userResp.refreshToken,
                user: {
                  ...userResp.user,
                  countryManage: cz,
                },
                countryZone: cz,
              };
            })
          );
        }),
        switchMap((response) => {
          if (response.accessToken) {
            this.accessToken = response.accessToken;
          }

          if (response.refreshToken) {
            this.refreshToken = response.refreshToken;
          }

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = {
            ...response.user,
            avatar: response.user?.image_url,
            countryManage: response.countryZone,
          };

          this._userSelectorService.onLoadCountryZone();
          this._userSelectorService.updateCountryZoneCode(
            response.countryZone[0].countryZoneCode,
            false
          );

          // Return true
          return of(true);
        }),
        catchError(() =>
          // Return false
          of(false)
        )
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    return this._httpClient
      .get<BaseResponse<UserLogin>>(this.getProfileAPI)
      .pipe(
        map((res) => res.result),
        switchMap((userResp) => {
          if (userResp.roleCode === 'HOST') {
            return of({
              user: userResp,
              countryZone: userResp.countryManage.map((m) => m.countryZone),
            });
          }

          return this.countryZoneService.all().pipe(
            take(1),
            map((cz) => {
              return {
                user: {
                  ...userResp,
                  countryManage: cz,
                },
                countryZone: cz,
              };
            })
          );
        }),
        switchMap((response) => {
          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = {
            ...response.user,
            avatar: response.user?.image_url,
            countryManage: response.countryZone,
          };

          this._userSelectorService.onLoadCountryZone();
          this._userSelectorService.updateCountryZoneCode(
            response.countryZone[0].countryZoneCode,
            false
          );

          // Return true
          return of(true);
        }),
        catchError(() =>
          // Return false
          of(false)
        )
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.clear();
    sessionStorage.clear();

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    let isACExpire = false;
    let isRFExpire = false;
    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      isACExpire = true;
    }

    if (AuthUtils.isTokenExpired(this.refreshToken)) {
      isRFExpire = true;
    }

    if (isACExpire && isRFExpire) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
