<mat-form-field class="fuse-mat-dense fuse-mat-no-subscript fuse-mat-rounded w-64">
    <mat-select [formControl]="filterCountryInputControl" placeholder="All Country" 
        (selectionChange)="onSelectCountry($event.value)">
        <div class="px-4 pt-5 sticky top-0 z-10 w-full bg-white">
            <mat-form-field class="w-full">
                <input matInput placeholder="Search" #countryZoneSearch>
            </mat-form-field>
        </div>
        <mat-option
            *ngFor="let country of allCountryZone | searchByKey: ['name']: countryZoneSearch.value; trackBy: trackByFn"
            [value]="country.countryZoneCode">
            {{country.name}}
        </mat-option>
    </mat-select>
</mat-form-field>